<template>
  <div class="pt-10 pb-20">
    <div class="max-w-sm mx-auto px-2 sm:px-0">
      <div class="flex justify-between mx-2 mb-8">
        <div @click="goBack" class="cursor-pointer flex-none my-auto text-4xl">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div class="w-32 md:w-36 my-auto flex-none">
          <img alt="Vue logo" src="../assets/Spotify_Logo.png" />
          <h1
            class="text-md font-bold flex justify-end -mt-2 pr-1 text-green-500"
          >
            Profiler
          </h1>
        </div>
      </div>
      <div v-if="profile" class="bg-white rounded-xl shadow-xl">
        <h1 class="text-center text-2xl font-bold p-5 text-green-600">
          {{ profile.name }}
        </h1>
        <div class="mb-5">
          <img
            v-if="profile.images.lg"
            class="object-cover h-80 w-full"
            :src="profile.images.lg"
            :alt="profile.name"
          />
          <img
            v-else
            class="object-cover h-80 w-full"
            src="../assets/placeholder.jpg"
            :alt="profile.name"
          />
        </div>
        <h1 class="uppercase text-center text-md font-semibold mb-2">
          Top Tracks <i class="fas fa-arrow-down"></i>
        </h1>
        <div>
          <Track-Row
            @click="playTrack(track.preview, idx)"
            v-for="(track, idx) in tracks"
            :key="idx"
            :track="track"
            :playing="activeTrack === idx && playing"
          />
        </div>
        <div class="py-5 flex justify-center">
          <a class="" :href="profile.link" target="_blank">
            <button
              class="bg-green-500 rounded-3xl px-3 py-2 uppercase font-bold text-white text-sm"
            >
              <span>Listen on Spotify</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";

import TrackRow from "@/components/TrackRow";
import { fetchArtist } from "@/api/api";

export default {
  name: "Artist",

  data() {
    return {
      profile: null,
      player: null,
      playing: false,
      activeTrack: -1,
    };
  },

  mounted() {
    this.getArtist();
  },

  unmounted() {
    this.resetPlayer();
  },

  computed: {
    tracks() {
      return this.profile.topTracks.slice(0, 5);
    },
  },

  methods: {
    async getArtist() {
      const artistId = this.$route.params.id;
      this.profile = await fetchArtist(artistId);
    },

    playTrack(preview, idx) {
      if (!preview) {
        this.resetPlayer();
        return
      }

      if (idx === this.activeTrack) {
        if (this.player.playing()) {
          this.player.pause();
          this.playing = false;
          return;
        }
      } else {
        this.resetPlayer();
        this.player = new Howl({
          src: [preview],
          format: ["mp3"],
          volume: 1,
          onend: this.resetPlayer
        });
      }
      this.activeTrack = idx;
      this.playing = true;
      this.player.play();
    },

    resetPlayer() {
      Howler.stop();
      if (this.player) this.player.unload();
      this.player = null;
      this.activeTrack = -1;
      this.playing = true;
    },

    goBack() {
      if (window.history.state.back) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
  },

  components: {
    TrackRow,
  },
};
</script>