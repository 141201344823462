<template>
  <div
    class="overflow-hidden cursor-pointer flex justify-between mx-2 my-1 border-2 border-gray-200 rounded-lg"
    :class="playing ? 'border-green-500' : 'border-gray-200'"
  >
    <div class="relative inline-block flex-none">
      <div v-if="playing" class="top-5 left-5 absolute object-center">
        <img class="h-6 w-6" alt="Playing Gif" src="../assets/playing.gif" />
      </div>
      <img :src="track.images.sm" alt="" />
    </div>
    <div class="ml-4 mr-2 my-auto text-left flex-grow">
      <p class="text-sm font-semibold text-gray-600">{{ trackName }} <i v-if="hasPreview" class="ml-1 text-xs text-gray-400 fas fa-play"></i></p>
      <p class="text-xs text-gray-400">{{ track.time }}</p>
    </div>

    <div class="h-8 w-8 my-auto mr-2 flex-none">
      <a @click.stop :href="track.link" target="_blank"
        ><img src="../assets/Spotify_Icon.png" alt="Spotify Icon" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackRow",

  props: {
    track: {
      type: Object,
      default: () => {},
    },

    playing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    trackName() {
      const title = this.track.title || "";
      return title.length > 50 ? title.slice(0, 50) + "..." : title;
    },

    hasPreview() {
      return this.track.preview;
    }
  },
};
</script>